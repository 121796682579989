import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import i18next from 'i18next'

import { setShowPreviewPopup, setShowPreviewPopupVerify, showEncryptPreviewPopup, showDecryptPreviewPopup } from '../actions/localStates'
import { createContext, uploadContextDataBinary, getDataByIdBinary, deleteContext } from '../actions/api'

class PreviewPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          link: null,
          uploaded: false,
          contextId: null,
          isImage: false,
          fileName: null,
          selectedViewer: ""
      };

      this.close = this.close.bind(this);
      this.hideTitle = this.hideTitle.bind(this);
  }

  componentDidMount () {
    console.log(this.props.createDSDefaultState.fileList[this.props.file])
    var contextId, _this = this, dataFileName, fileExtension, extensions, viewer, link;

    this.props.actions.createContext("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1", "")
    .then((response) => {
      this.setState({"contextId": response.contextId})
      this.setState({"isImage": false})
     contextId = response.contextId;

     if (this.props.file.type !== undefined) {
        function hasSingleExtension(filename) {
            const parts = filename.split('.');
            return parts.length === 2;
        }

        if (this.props.base.showPreviewPopupVerify && hasSingleExtension(this.props.defaultState.fileWithDSName)) {
          dataFileName = this.props.defaultState.fileWithDSName;
        } else {
          dataFileName = this.props.defaultState.fileWithDSName.lastIndexOf(".") < 2 ? this.props.defaultState.fileWithDSName : this.props.defaultState.fileWithDSName.substr(0, this.props.defaultState.fileWithDSName.lastIndexOf("."));
        }
        
     } else {
        if (_this.props.base.showPreviewPopup === true && _this.props.createDSDefaultState.fileList[this.props.file] !== undefined) {
           dataFileName = _this.props.createDSDefaultState.fileList[this.props.file].name || _this.props.createDSDefaultState.fileList[this.props.file].fileName
        }
     }

     if (this.props.base.showEncryptPreviewPopup) {
        dataFileName = _this.props.encryptDefaultState.fileList[this.props.file].name || _this.props.encryptDefaultState.fileList[this.props.file].fileName
     }

     if (this.props.base.showDecryptPreviewPopup) {
        dataFileName = _this.props.decryptDefaultState.fileList[this.props.file].name.lastIndexOf(".") < 2 ? this.props.decryptDefaultState.fileList[this.props.file].name : this.props.decryptDefaultState.fileList[this.props.file].name.substr(0, this.props.decryptDefaultState.fileList[this.props.file].name.lastIndexOf("."));
        // dataFileName = _this.props.decryptDefaultState.fileList[this.props.file].name || _this.props.decryptDefaultState.fileList[this.props.file].fileName
     }

     this.setState({fileName: dataFileName})

     fileExtension = dataFileName.substring(dataFileName.lastIndexOf('.') + 1, dataFileName.length) || dataFileName;
     console.log(fileExtension)

     if (this.props.base.showPreviewPopupVerify) {
      function getFirstExtension(filename) {
            const dotIndex = filename.indexOf('.');
            return dotIndex !== -1 ? filename.substring(dotIndex + 1).split('.')[0] : '';
        }

        fileExtension = getFirstExtension(this.props.defaultState.fileWithDSName)

        function removeExtraExtensions(filename) {
            return filename.replace(/^([^\.]+\.[^\.]+)(\..+)*$/, '$1');
        }

        dataFileName = removeExtraExtensions(dataFileName)
     }

     fileExtension = fileExtension.toLowerCase();

     var fileToSend = _this.props.file.type !== undefined ? _this.props.file : _this.props.createDSDefaultState.fileList[this.props.file]

     if (this.props.base.showEncryptPreviewPopup) {
        fileToSend = _this.props.encryptDefaultState.fileList[this.props.file]
     }

     if (this.props.base.showDecryptPreviewPopup) {
        const b64toBlob = (b64Data, contentType, sliceSize=512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, {type: contentType});
          return blob;
        }

        if (this.props.base.isVtco) {
          var blob = b64toBlob(this.props.decryptDefaultState.decryptedFiles[this.props.file], fileExtension);

          fileToSend = blob
        } else {
          fileToSend = this.props.decryptDefaultState.decryptedFiles[this.props.file]
        }
     }

      extensions = {
        gDocs:['txt','rtf'],
        office:['doc','docx','xls','xlsx', 'ppt', 'pptx'],
        viewerJs:['odt','ods','pdf'],
        standartViewer:['jpg', 'jpeg', 'png', 'bmp']
      }

      for(var i in extensions) {
        if(extensions[i].indexOf(fileExtension) >= 0) {
          viewer = i;
          break;
        }
      }

     _this.props.actions.uploadContextDataBinary("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1", contextId, fileToSend, dataFileName, "")
     .then((response) => {
       _this.props.actions.getDataByIdBinary("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1", contextId, dataFileName, "")
       .then((response) => {

        // eslint-disable-next-line
        switch(viewer){
          case 'gDocs':
            _this.setState({selectedViewer: "gDocs"})
            link = 'https://docs.google.com/viewer?url=' + encodeURIComponent("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1/context/" + contextId + "/data/" + dataFileName) + '&embedded=true&hl=';
            break;
          case 'office':
            _this.setState({selectedViewer: "office"})
            link = 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1/context/" + contextId + "/data/" + dataFileName);
            break;
          case 'viewerJs':
            _this.setState({selectedViewer: "viewerJs"})
            link = '../js/ViewerJS/index.html?type=' + fileExtension + '#https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1/context/' + contextId + "/data/" + dataFileName;
            break;

          case 'standartViewer':
            _this.setState({selectedViewer: "standartViewer"})
            link = 'https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1/context/' + contextId + "/data/" + dataFileName;
            _this.setState({"isImage": true})
            break;

          case 'default':
            break;
        }

         _this.setState({"link": link})
         _this.setState({"uploaded": true})
       })
     })
    })
  }

  componentWillUnmount () {
  }

  hideTitle () {
    var _this = this;
    setTimeout(() => {
      var iframe = document.getElementById("iframeId");
      if (iframe === null) {
        return;
      }

      if (_this.state.selectedViewer === "viewerJs") {
        var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
        var name = innerDoc.getElementById("documentName");
        name.style.display = "none"
      }
    }, 100);
  }

  close () {
    this.props.actions.deleteContext("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1", this.state.contextId, "")
    .then((response) => {
      console.log(response)
      this.setState({"contextId": null})
      this.setState({selectedViewer: ""})
      this.props.actions.setShowPreviewPopupVerify(false);
      this.props.actions.setShowPreviewPopup(false);
      this.props.actions.showEncryptPreviewPopup(false);
      this.props.actions.showDecryptPreviewPopup(false);
    })
    
  }

  render() {
      return (
          <div className='popup'>
              <div className={"popup_inner_user_token_pin"}>
                <div style={{textAlign: "right"}}>
                  <i className="fa fa-times-circle" style={{fontSize: "28px", cursor: "pointer"}} onClick={this.close}></i>
                </div>
                  <div className="col-12" style={{marginTop: "5px", paddingRight: "0px", paddingLeft: "10px"}}>
                      {
                        this.state.uploaded
                        ? this.state.isImage
                          ? <img src={this.state.link} alt={this.state.fileName} width='780' height='550'></img>
                          : <iframe src={this.state.link} id="iframeId" onLoad={this.hideTitle()} title={this.state.fileName} width='780' height='550' scrolling="no" allowtransparency="yes" allowFullScreen webkitallowfullscreen></iframe>
                        : null
                      }
                      
                      {/*<label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("sessionEndTitle")}</label>
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-info" id="submitPin" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.login}>{i18next.t("continueLabel")}</button>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.cancel}>{i18next.t("cancelLabel")}</button>
                      </div>*/}
                      {/*<button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}}>{i18next.t("close")}</button>*/}
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        createDSDefaultState: state.createDSInputReducer,
        defaultState: state.verifyDSInputReducer,
        encryptDefaultState: state.encryptFilesReducer,
        decryptDefaultState: state.decryptReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setShowPreviewPopup, createContext, uploadContextDataBinary,
      getDataByIdBinary, deleteContext, setShowPreviewPopupVerify,
      showEncryptPreviewPopup, showDecryptPreviewPopup
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPopup);
