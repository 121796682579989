import React, { Component } from 'react'
import { connect } from 'react-redux'
import { languageSwitchItem } from '../actions/locales'
import { createSession, sendInputData, sendDSData, sendSessionOption, verifyDSData,
			getVerifiedData, deleteSession, getSignedData, modifyDS, getModifiedDSData, getModifyDSResult, getQR } from '../actions/api'
import { setVerifyResultData, setFileSignedData, setCadesType, setModifiedDSData, setSignatureDifferenceType, setVerifyInputFile, setVerifyDSInputFile,
			setVerifyResultForQR, setIsLoading, setErrorData, setShowErrorPopup, setVerifyResultDataForPrint } from '../actions/localStates'
import { bindActionCreators } from 'redux';
import i18next from 'i18next'

import VerifyDSTab from '../components/body/VerifyDSTab'

class VerifyDSContainer extends Component {

	constructor(props) {
    super(props);

	    this.state = {
			inputFile: null,
			inputDSFile: null,
			baseUrl: "https://local.cipher.kiev.ua:9090/api/v1/ticket/",
			uuid: null,
			isModify: false,
			responseMessage: "",
			responseFailureCause: "",
			baseFileInput: null,
			verifyResultMessage: "",
			modifyResultMessage: "",
			verifyResultsArray: [],
			degreeOfNesting: 1,
			verifyResultsArrayForPrint: []
		}

		this.child = React.createRef();

		this.verifyDS = this.verifyDS.bind(this);
		this.setFiles = this.setFiles.bind(this);

		this.createQRData = this.createQRData.bind(this);
		this.getQR = this.getQR.bind(this);

		this.setVerifyResultsArrayForPrint = this.setVerifyResultsArrayForPrint.bind(this);
	}

	formatDate(dateInString) {
        var localeDateOptions = {
            "day": "numeric",
            "month": "numeric",
            "year": "numeric",
            "hour": "2-digit",
            "minute": "2-digit",
            "second": "2-digit",
            "timeZoneName": "short"
        }

        return new Date(dateInString).toLocaleDateString(i18next.language, localeDateOptions);
    }

    constructTimestampDetails(aTs, aTsType, forQR) {
        let contentTs = i18next.t("dataTs");
        let signatureTs = i18next.t("dsTs");
        let tsaDS = i18next.t("tsaCertificateSerialNumberDS");
        let tsa = i18next.t("tsaCertificateSerialNumber");
        let result = [];

        let tsTypeString = function () {
            if (aTsType === "content") {
                return contentTs;
            }
            if (aTsType === "signature") {
                return signatureTs;
            }
            return "";
        }

        let tsaTsTypeString = function () {
            if (aTsType === "content") {
                return tsa;
            }
            if (aTsType === "signature") {
                return tsaDS;
            }
            return "";
        }

        if (forQR) {
        	var str = ""
        	if (aTs && aTs.dateTime) {
	            if (aTs.isFailure) {
	                result.push(tsTypeString() + i18next.t("ds:errorWhileVerifying"));
	            } else {
	            	str += aTs.tsaCertificateSerialNumber ? tsaTsTypeString() + aTs.tsaCertificateSerialNumber + "\n" : "";

		            str += tsTypeString() + ": " + (aTs.isValid ? i18next.t("ds:sheIsValid") : i18next.t("ds:sheIsInvalid")) + "; " + this.formatDate(aTs.dateTime)

	            	result.push(str);
	            }
	        }

	        return result
        } else {
        	if (aTs && aTs.dateTime) {
	            if (aTs.isFailure) {
	                result.push(<div>{tsTypeString() + i18next.t("ds:errorWhileVerifying")}</div>);
	            } else {
	            	result.push(
	            		aTs.tsaCertificateSerialNumber
	            		? <div><span className="font-weight-bold">{tsaTsTypeString()}</span>
		                	{
			            		aTs.tsaCertificateSerialNumber
		                	}
		                </div>
		                : <div><span className="font-weight-bold">{tsTypeString()}</span>
	            			{
			            		": "
			                    + (aTs.isValid ? i18next.t("ds:sheIsValid") : i18next.t("ds:sheIsInvalid"))
			                    + "; "
			                    + this.formatDate(aTs.dateTime)
		                	}
		                </div>
	                );
	            }
	        }
	        return result;
        }        
    }

    async getQR (data) {
    	var _this = this, result;
		result = await _this.props.actions.getQR(_this.props.base.qrCodeUrl, data, _this.props.base.token)
		var objectURL = URL.createObjectURL(result);
		return objectURL;
	}

	createQRData (verifyResults) {
		var _this = this;

		if (!this.props.dafaultState.generateQR) {
			return;
		}
		
		var str = "", arr = [], tempData;

		const safeDateParse = (inputData, invalidDate) =>
	    Date.parse(inputData) || invalidDate;

		const highestPriorityDate = (inputObject) =>
		    [
		        inputObject.signatureTimestamps.reduce(
		            (a, b) =>
		                Math.min(
		                    safeDateParse(a.dateTime, Number.MAX_SAFE_INTEGER),
		                    safeDateParse(b.dateTime, Number.MAX_SAFE_INTEGER)
		                ),
		            Number.MAX_SAFE_INTEGER
		        ),
		        safeDateParse(
		            inputObject.contentTimestamp.dateTime,
		            Number.MAX_SAFE_INTEGER
		        ),
		        Date.parse(inputObject.signingDateTime),
		    ].filter((a) => a !== Number.MAX_SAFE_INTEGER)[0];
		
		verifyResults
        .sort((first, second) => {
            const firstHash = highestPriorityDate(first);
            const secondHash = highestPriorityDate(second);

            if (firstHash < secondHash) {
                return -1;
            }

            if (firstHash > secondHash) {
                return 1;
            }

            return 0;
        })
        .map(highestPriorityDate)

		async function processArray(array) {
			for (const item of array) {

				str += i18next.t("fileName") + _this.props.verifyDefaultState.fileWithDS.name + "\n";

				str += (item.signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" && item.signerInfo.extensionsCertificateInfo.value.isQualified.value === "true") ? i18next.t("ds:qualifiedTitle") + "\n" : i18next.t("ds:unqualifiedTitle") + "\n"

				if (item.signerInfo.ownerCertificateInfo) {
					if (item.signerInfo.ownerCertificateInfo.value.ownerFullName.value) {
						str += i18next.t("ds:signer") + item.signerInfo.ownerCertificateInfo.value.ownerFullName.value + "\n";
					}

					if (item.signerInfo.ownerCertificateInfo.value.ownerPosition.value) {
						str += i18next.t("position") + item.signerInfo.ownerCertificateInfo.value.ownerPosition.value + "\n"
					}

					if (item.signerInfo.ownerCertificateInfo.value.ownerOrganization.value) {
						str += i18next.t("organization") + item.signerInfo.ownerCertificateInfo.value.ownerOrganization.value + "\n"
					}

					if (item.signerInfo.extensionsCertificateInfo.value.personalData.value.drfou.value) {
						str += i18next.t("ds:drfou") + item.signerInfo.extensionsCertificateInfo.value.personalData.value.drfou.value + "\n"
					}

					if (item.signerInfo.extensionsCertificateInfo.value.personalData.value.edrpou.value) {
						str += i18next.t("ds:edrpou") + item.signerInfo.extensionsCertificateInfo.value.personalData.value.edrpou.value + "\n"
					}
					
					if (item.signerInfo.extensionsCertificateInfo.value.personalData.value.eddrun.value) {
						str += i18next.t("ds:eddrun") + item.signerInfo.extensionsCertificateInfo.value.personalData.value.eddrun.value + "\n"
					}

					if (item.signerInfo.issuerCertificateInfo.issuerOrganization) {
						str += i18next.t("issuerOrganization") + item.signerInfo.issuerCertificateInfo.issuerOrganization + "\n"
					}
					
					if (item.signerInfo.certificateSerialNumber.value) {
						str += i18next.t("certificateSerialNumberTitle") + item.signerInfo.certificateSerialNumber.value.replace(/\s/g, "") + "\n"
					}

					str += i18next.t("ds:ca") + item.signerInfo.issuerCertificateInfo.value.issuerFullName.value + "\n"
				}

				str += "\n"

			    if (item.signingDateTime !== undefined && item.signingDateTime.length > 0) {
			    	str += i18next.t("ds:dsDate") + _this.formatDate(item.signingDateTime) + "\n"
			    }
			    
			    if (item.contentTimestamp !== undefined) {
			    	str += _this.constructTimestampDetails(item.contentTimestamp, "content", true) + "\n"
			    }

		    	if (item.signatureTimestamps && item.signatureTimestamps.length > 0) {
					for (var j = 0; j < item.signatureTimestamps.length; j += 1) {
						str += _this.constructTimestampDetails(item.signatureTimestamps[j], "signature", true) + "\n"
					}
				}

				if (item.signerInfo.extensionsCertificateInfo.value.isQualified.value.length > 0) {
					var qualifiedCertTitle = item.signerInfo.extensionsCertificateInfo.value.isQualified.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")
					str += i18next.t("ds:qualifiedCertTitle") + qualifiedCertTitle + "\n"
				}

				if (item.signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value.length > 0) {
					var QSCDTitle = item.signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")
					str += i18next.t("ds:QSCDTitle") + QSCDTitle + "\n"
				}

				if (item.signerInfo.extensionsCertificateInfo.value.qscdTypeName) {
					var QSCDTypeTitle = item.signerInfo.extensionsCertificateInfo.value.qscdTypeName.value === null ? i18next.t("ds:notDefined") : item.signerInfo.extensionsCertificateInfo.value.qscdTypeName.value
					str += i18next.t("ds:QSCDTypeTitle") + QSCDTypeTitle + "\n"
				}

				console.log(str)

				tempData = await _this.getQR(str);
				arr.push(tempData)

				str = "";
			}

			_this.props.actions.setVerifyResultForQR(arr);

		}

		processArray(verifyResults)	
	}

	componentDidMount() {
		
	}

	setFiles() {
		if (this.props.dafaultState.showUpgradeCard) {
			this.setState({isModify: true})
			this.verifyDS();
		} else {
			this.setState({isModify: false})
			this.verifyDS();
		}
	}

	setVerifyResultsArrayForPrint (data) {
		var tempArr;
		tempArr = this.state.verifyResultsArrayForPrint

		if (Array.isArray(data.verifyResults)) {
			data.verifyResults.forEach((element) => {
				tempArr.push(element)
			})
		} else {
			tempArr.push(data[0])
		}
		

		this.setState({verifyResultsArrayForPrint: tempArr}) 
	}

	tryToVerifyInsertedData (data) {
		var _this = this;

		this.props.actions.setIsLoading(true)
		var needToGetSignedData = true, exit = false, tempData = this.state.verifyResultsArray;
				this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, data)
	            .then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.dafaultState))
	            .then(() => this.props.actions.verifyDSData(this.props.connectionStatus.baseUrl, this.state.uuid))
	            .then(() => this.props.actions.getVerifiedData(this.props.connectionStatus.baseUrl, this.state.uuid))
	            .then((response) => {
	            	if (response.failureCause !== undefined && response.failureCause.length > 0) {
	            		if (response.failureCause === "Неможливо визначити формат електронного підпису та послідуючий порядок його перевірки.") {
	            			// this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid)
	            			exit = true;
	            			return;

	            		}
	            		this.setState({"verifyResultMessage": response.failureCause})
	            		this.props.actions.setVerifyResultData(response.verifyResults, "dsvVerifyResultsTextDs", "verifyResults")
	            		this.props.actions.setVerifyResultDataForPrint(response.verifyResults)
	            	} else {

	            		response.verifyResults.forEach((element) => {
	            			element.degreeOfNesting = _this.state.degreeOfNesting;
							tempData.push(element);
						});

						_this.setState({degreeOfNesting: _this.state.degreeOfNesting += 1})
						_this.setState({verifyResultsArray: tempData})

						// function recursiveCheckAndUpdate(obj, response) {
						// 	// currentResponse.forEach((element) => {
						// 	// 	tempArr.push(element);
						// 	// });
						//     if ('verifyResults' in obj) {
						//         if (Array.isArray(obj.verifyResults)) {
						//             obj.verifyResults[obj.verifyResults.length - 1].verifyResults = response.verifyResults;
						//             _this.setVerifyResultsArrayForPrint(response)
						//         } else {
						//             obj.verifyResults = response.verifyResults;
						//             _this.setVerifyResultsArrayForPrint(response)
						//         }
						//     } else {
						//         for (let key in obj) {
						//             if (obj[key] && typeof obj[key] === 'object') {
						//                 recursiveCheckAndUpdate(obj[key], response);
						//             }
						//         }
						//     }

						//     tempData = [obj]
						// }

						// if ('verifyResults' in tempData[tempData.length - 1]) {
						// 	recursiveCheckAndUpdate(tempData[tempData.length - 1], response);
						// } else {
						// 	_this.setVerifyResultsArrayForPrint(response)
						// 	tempData[tempData.length - 1].verifyResults = response.verifyResults;
						// }

						// function recursiveCheckAndUpdate(obj, response) {
						//     if (typeof obj !== 'object' || obj === null) return;

						//     if ('verifyResults' in obj) {
						//         if (Array.isArray(obj.verifyResults)) {
						//             const lastItem = obj.verifyResults[obj.verifyResults.length - 1];
						//             if (typeof lastItem === 'object') {
						//                 lastItem.verifyResults = response.verifyResults;
						//             } else {
						//                 obj.verifyResults.push(response.verifyResults);
						//             }
						//         } else {
						//             obj.verifyResults = [obj.verifyResults, response.verifyResults].flat();
						//         }
						//     } else {
						//         for (const key in obj) {
						//             if (typeof obj[key] === 'object' && obj[key] !== null) {
						//                 recursiveCheckAndUpdate(obj[key], response);
						//             }
						//         }
						//     }
						// }

						// if (tempData.length > 0 && 'verifyResults' in tempData[tempData.length - 1]) {
						//     recursiveCheckAndUpdate(tempData[tempData.length - 1], response);
						// } else {
						//     if (tempData.length > 0) {
						//         tempData[tempData.length - 1].verifyResults = response.verifyResults;
						//     }
						// }
						
						// _this.setState({verifyResultsArray: tempData}) 		
	            		
	            		response.verifyResults.map((res) => {
	            			if ((res.signatureFormat === "XADES_B_B" || res.signatureFormat === "XADES_B_LT" || res.signatureFormat === "XADES_B_LTA") || (res.signatureType === "ASIC_S" || res.signatureFormat === "ASIC_E")) {
	            				needToGetSignedData = false;
	            			}
	            			console.log(res)
	            		})
                		console.log(response)
	            	}

	            	if (this.state.baseFileInput !== null) {
	            		this.props.actions.setVerifyDSInputFile(this.state.baseFileInput)
	            		this.setState({baseFileInput: null})
	            	}

	            	this.createQRData(this.props.verifyDefaultState.verifyResults);
            	})
            	.then(() => {
            		if (exit) {
            			this.props.actions.setVerifyResultData(tempData, "dsvVerifyResultsTextDs", "verifyResults")
            			
      //       			function flattenVerifyResults(obj) {
						//     if (typeof obj !== 'object' || obj === null) return [];

						//     let results = [];

						//     function extractVerifyResults(currentObj) {
						//         if (typeof currentObj !== 'object' || currentObj === null) return;
						        
						//         if ('verifyResults' in currentObj) {
						//             let verifyData = { ...currentObj };
						//             delete verifyData.verifyResults;
						            
						//             results.push(verifyData);

						//             if (currentObj.verifyResults.length > 1) {
						//             	for (var i = 0; i < currentObj.verifyResults.length; i += 1) {
						//             		extractVerifyResults(currentObj.verifyResults[i]);
						//             	}
						//             } else {
						//             	extractVerifyResults(currentObj.verifyResults[0]);
						//             }
						            
						//         } else {
						//             results.push(currentObj);
						//         }
						//     }

						//     extractVerifyResults(obj[0]);

						//     return results;
						// }

						// let flattenedResults = flattenVerifyResults(tempData);

      //       			this.props.actions.setVerifyResultDataForPrint(flattenedResults)
      					this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid)
            			this.props.actions.setIsLoading(false)
            		}

            		if (!exit) {
            			this.props.actions.getSignedData(this.props.connectionStatus.baseUrl, this.state.uuid, needToGetSignedData)
            			.then((response) => {
			            	if (response !== null) {
			            		this.props.actions.setFileSignedData(response)
			            	}

			            	if (this.props.dafaultState.signatureType === 'attached' && !exit) {
			            		this.tryToVerifyInsertedData(response)
			            	}
		            	})
            		}
			})
	}

	verifyDS() {
		var needToGetSignedData = true;
		this.setState({degreeOfNesting: 1})
		this.props.actions.createSession(this.props.connectionStatus.baseUrl)
        	.then((response) => {
				this.setState({uuid: response.ticketUuid})
				console.log(response);
				return this.props.dafaultState.signatureType === 'detached' ? this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.verifyDefaultState.fileInput)
				.then(() => {
					return this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.verifyDefaultState.fileWithDS)
						.then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.dafaultState))
			            .then(() => this.props.actions.verifyDSData(this.props.connectionStatus.baseUrl, this.state.uuid))
			            .then(() => this.props.actions.getVerifiedData(this.props.connectionStatus.baseUrl, this.state.uuid))
			            .then((response) => {
			            	if (response.failureCause !== undefined && response.failureCause.length > 0) {
			            		this.setState({"verifyResultMessage": response.failureCause})
			            		this.props.actions.setVerifyResultData(response.verifyResults, "dsvVerifyResultsTextDs", "verifyResults")
			            	} else {
			            		// this.setState({"verifyResultMessage": i18next.t("ds:verifiedDSSuccessTitle": "verifiedDSSuccessTitle")})
			            		this.props.actions.setVerifyResultData(response.verifyResults, "dsvVerifyResultsTextDs", "verifyResults")
		                		console.log(response)
			            	}
			            	if (this.state.baseFileInput !== null) {
			            		this.props.actions.setVerifyDSInputFile(this.state.baseFileInput)
			            		this.setState({baseFileInput: null})
			            	}

			            	this.createQRData(this.props.verifyDefaultState.verifyResults);
		                })
			            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
			            .then(() => this.modifyDS())
				}) : this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.verifyDefaultState.fileWithDS)

	            .then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.dafaultState))
	            .then(() => this.props.actions.verifyDSData(this.props.connectionStatus.baseUrl, this.state.uuid))
	            .then(() => this.props.actions.getVerifiedData(this.props.connectionStatus.baseUrl, this.state.uuid))
	            .then((response) => {
	            	if (response.failureCause !== undefined && response.failureCause.length > 0) {
	            		if (response.verifyResults !== undefined && response.verifyResults.length === 0) {
	            			this.setState({"verifyResultMessage": response.failureCause})
	            		}

	            		this.setState({verifyResultsArray: response.verifyResults})
	            		this.props.actions.setVerifyResultData(response.verifyResults, "dsvVerifyResultsTextDs", "verifyResults")
	            	} else {
	            		// this.setState({"verifyResultMessage": i18next.t("ds:verifiedDSSuccessTitle": "verifiedDSSuccessTitle")})
	            		this.setState({verifyResultsArray: response.verifyResults})
	            		this.setState({verifyResultsArrayForPrint: response.verifyResults})
	            		this.props.actions.setVerifyResultData(response.verifyResults, "dsvVerifyResultsTextDs", "verifyResults")
	            		this.props.actions.setVerifyResultDataForPrint(response.verifyResults)
	            		response.verifyResults.map((res) => {
	            			if ((res.signatureFormat === "XADES_B_B" || res.signatureFormat === "XADES_B_LT" || res.signatureFormat === "XADES_B_LTA") || (res.signatureType === "ASIC_S" || res.signatureFormat === "ASIC_E")) {
	            				needToGetSignedData = false;
	            			}
	            			console.log(res)
	            		})
                		console.log(response)
	            	}
	            	if (this.state.baseFileInput !== null) {
	            		this.props.actions.setVerifyDSInputFile(this.state.baseFileInput)
	            		this.setState({baseFileInput: null})
	            	}

	            	this.createQRData(this.props.verifyDefaultState.verifyResults);
            	})
            	.then(() => this.props.actions.getSignedData(this.props.connectionStatus.baseUrl, this.state.uuid, needToGetSignedData))
            	.then((response) => {
	            	if (response !== null) {
	            		this.props.actions.setFileSignedData(response)
	            	}

	            	if (this.props.dafaultState.signatureType === 'attached' && !this.state.isModify) {
	            		this.tryToVerifyInsertedData(response)
	            	}
            	})
	            .then(() => {
	            	if (this.props.dafaultState.signatureType !== 'attached') {
	            		this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid)
	            	}
	            })
	            .then(() => this.modifyDS())
        })
    }

    modifyDS() {
    	var errorMessage;
    	if (!this.state.isModify) {

    		if (this.state.verifyResultMessage.length > 0) {
    			errorMessage = {
                  message: this.state.verifyResultMessage
                }
                this.props.actions.setErrorData(errorMessage)
                this.props.actions.setShowErrorPopup(true)
    			// alert(this.state.verifyResultMessage);
    		}

    		if (this.props.dafaultState.signatureType === 'detached') {
    			this.props.actions.setIsLoading(false)
    		}
    		
    		this.setState({"verifyResultMessage": ""})
    		return;
    	}

    	if (this.props.dafaultState.signatureType === 'detached') {
    		this.props.actions.setCadesType("CAdESXLong");
    		this.props.actions.setSignatureDifferenceType([]);
			this.props.actions.createSession(this.props.connectionStatus.baseUrl)
	        .then((response) => {
				this.setState({uuid: response.ticketUuid})
				console.log(response);
				return this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.verifyDefaultState.fileInput)
				.then(() => {
					return this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.verifyDefaultState.fileWithDS)
		            .then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.dafaultState))
		            .then(() => this.props.actions.modifyDS(this.props.connectionStatus.baseUrl, this.state.uuid))
		            .then(() => this.props.actions.getModifiedDSData(this.props.connectionStatus.baseUrl, this.state.uuid))
		            .then((response) => {
		            	if (response !== null) {
		            		this.props.actions.setModifiedDSData(response)
		            	}
	                })
		            .then(() => this.props.actions.getModifyDSResult(this.props.connectionStatus.baseUrl, this.state.uuid)
		            .then((response) => {
		            	if (response.modifyResults !== undefined && response.modifyResults.length > 0) {
		            		this.props.actions.setSignatureDifferenceType(response.modifyResults)
		        		}
		            	if (response.failureCause !== undefined && response.failureCause.length > 0) {
		            		// alert(response.failureCause);
		            		this.setState({"modifyResultMessage": response.failureCause})
		            	} else {

		            		this.setState({"modifyResultMessage": response.message})
		            		console.log(response)
		            	}

		            	if (this.state.modifyResultMessage.length > 0) {
		            		if (this.state.verifyResultMessage.length > 0) {
		            			errorMessage = {
		                          message: this.state.verifyResultMessage,
		                          messageSecond: this.state.modifyResultMessage
		                        }
		                        this.props.actions.setErrorData(errorMessage)
		                        this.props.actions.setShowErrorPopup(true)
		            			// var msg = this.state.verifyResultMessage + '\n' + this.state.modifyResultMessage
	            				// alert(msg)
		            		}
		            		
	            			this.setState({"verifyResultMessage": ""})
	            			this.setState({"modifyResultMessage": ""})
		            	} else {
		            		errorMessage = {
	                          message: this.state.verifyResultMessage
	                        }
	                        this.props.actions.setErrorData(errorMessage)
	                        this.props.actions.setShowErrorPopup(true)
		            		// alert(this.state.verifyResultMessage)
		            		this.setState({"verifyResultMessage": ""})
		            	}
		            })
		            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
		            .then(() => this.props.actions.setIsLoading(false)))
				})
	        })
    	} else {
    		this.modifyAttachedDS()
    	}
	}

	modifyAttachedDS() {
		var errorMessage;
    	this.props.actions.setCadesType("CAdESXLong");
    	this.props.actions.setSignatureDifferenceType([]);
		this.props.actions.createSession(this.props.connectionStatus.baseUrl)
        .then((response) => {
			this.setState({uuid: response.ticketUuid})
			console.log(response);
			return this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.verifyDefaultState.fileWithDS)
            .then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.dafaultState))
            .then(() => this.props.actions.modifyDS(this.props.connectionStatus.baseUrl, this.state.uuid))
            .then(() => this.props.actions.getModifiedDSData(this.props.connectionStatus.baseUrl, this.state.uuid))
            .then((response) => {
            	if (response !== null) {
            		this.props.actions.setModifiedDSData(response)
            	}
            })
            .then(() => this.props.actions.getSignedData(this.props.connectionStatus.baseUrl, this.state.uuid))
        	.then((response) => {
            	if (response !== null) {
            		this.props.actions.setFileSignedData(response)
            	}
        	})
            .then(() => this.props.actions.getModifyDSResult(this.props.connectionStatus.baseUrl, this.state.uuid)
            .then((response) => {
            	if (response.modifyResults !== undefined && response.modifyResults.length > 0) {
        			this.props.actions.setSignatureDifferenceType(response.modifyResults)
        		}

            	if (response.failureCause !== undefined && response.failureCause.length > 0) {
            		// alert(response.failureCause);
            		this.setState({"modifyResultMessage": response.failureCause})
            	} else {

            		this.setState({"modifyResultMessage": response.message})
            		console.log(response)
            	}

            	if (this.state.modifyResultMessage.length > 0) {
            		if (this.state.verifyResultMessage.length > 0) {
            			errorMessage = {
                          message: this.state.verifyResultMessage,
                          messageSecond: this.state.modifyResultMessage
                        }
                        this.props.actions.setErrorData(errorMessage)
                        this.props.actions.setShowErrorPopup(true)
	        // 			var msg = this.state.verifyResultMessage + '\n' + this.state.modifyResultMessage
	    				// alert(msg)
	        		}
        			this.setState({"verifyResultMessage": ""})
        			this.setState({"modifyResultMessage": ""})
            	} else {
            		// alert(this.state.verifyResultMessage)
            		this.setState({"verifyResultMessage": ""})
            	}
            })
            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
            .then(() => this.props.actions.setIsLoading(false)))
        })
	}

	render() {
		return (
			<div>
				<VerifyDSTab setInputs={this.setFiles} ref={this.child} />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
        locales: state.i18n,
        localesReducer: state.localesReducer,
        verifyInputDS: state.setVerifyDSInput,
        dafaultState: state.verifyDSOptionsReducer,
        verifyDefaultState: state.verifyDSInputReducer,
        connectionStatus: state.connectionStatusReucer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    createSession,
	    sendInputData,
	    sendDSData, getQR,
	    sendSessionOption,
	    verifyDSData,
	    getVerifiedData,
	    deleteSession,
	    setVerifyResultData,
	    setFileSignedData,
	    getSignedData, modifyDS,
	    getModifiedDSData, getModifyDSResult,
	    setCadesType, setVerifyResultForQR,
	    setModifiedDSData,
	    setSignatureDifferenceType, setVerifyInputFile,
	    setVerifyDSInputFile,
	    setIsLoading, setErrorData, setShowErrorPopup,
	    setVerifyResultDataForPrint
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDSContainer);
